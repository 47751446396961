function getCookie(cName) {
  if (document.cookie.length > 0) {
    let cStart = document.cookie.indexOf(cName + "=");
    if (cStart != -1) {
      cStart = cStart + cName.length + 1;
      let cEnd = document.cookie.indexOf(";", cStart);
      if (cEnd == -1) {
        cEnd = document.cookie.length;
      }
      return decodeURIComponent(document.cookie.substring(cStart, cEnd));
    }
  }
  return "";
}

export function isMobile() {
  // отключить проверку на мобильный 09.04.2024 по запросу Александра Чуракова
  return false; //"ontouchstart" in window;
}

export function isDirectEntryByCookie() {
  const filter = ["", "kseo", "yadirect", "app"];
  const isNoPartnerCookie = filter.includes(getCookie("partner"));
  const isNoSeoCookie = filter.includes(getCookie("seo"));

  return isNoPartnerCookie && isNoSeoCookie;
}

export function isPartnerApp() {
  return getCookie("partner").endsWith("app");
}
