<template>
  <header :class="'form-header _' + theme">
    <Button v-if="isShowBack" class="ml-md-3 mr-2" type="clear" @click="back">
      <Icon v-if="!isRequest" type="arrow-left" :to="toColorName" />
      <Icon v-if="isRequest" type="request" :to="toColorName" />
    </Button>
    <div class="form-header__section">
      <img :src="`/${theme}/img/logo.svg`" :alt="theme" />
    </div>
    <div v-if="showInfo" class="form-header__section _info">
      <span>
        Берете <b>{{ amount.toLocaleString() }} ₽</b>
      </span>
      <span>
        Срок <b>{{ term }} {{ morphDay }}</b>
      </span>
    </div>
  </header>
</template>

<script>
import morph from "@frontend/beryberu-main/services/morph";

export default {
  name: "FormHeaderStatic",
  props: {
    theme: {
      type: String,
      default: "",
    },
    term: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    isShowBack: {
      type: Boolean,
      default: false,
    },
    isRequest: {
      type: Boolean,
      default: false,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    morphDay() {
      return morph(["день", "дня", "дней"], this.term);
    },
    toColorName() {
      return this.theme === "dabank" ? "green" : "";
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style lang="css" src="./form-header.css"></style>
