<template>
  <div :class="'form _' + theme">
    <div class="form__header">
      <FormHeader
        class="container"
        :theme="theme"
        :term="application.term"
        :amount="application.amount"
        :is-show-back="showBack"
        :is-request="request"
        :show-info="showInfo"
        @back="onBack"
      />
      <ProgressLine :theme="theme" :total="6" :step="step" />
    </div>
    <div class="form__view-wrapper">
      <h1 v-if="application.status !== 'approved'" class="container mb-4">
        {{ ($route.meta || {}).title }}
      </h1>
      <slot />
    </div>
  </div>
</template>

<script>
import application from "@/mixins/application";
import FormHeader from "./components/form-header/form-header.vue";
import ProgressLine from "./components/progress-line/progress_line.vue";
import statusToRoute from "@/router/status-to-route";
import { reloadPage } from "@/helpers/reload-page";
import { isPartnerApp } from "@/helpers/white-loan";

export default {
  name: "PrimaryForm2",
  components: {
    FormHeader,
    ProgressLine,
  },
  mixins: [application],
  beforeRouteUpdate(to, from, next) {
    // eslint-disable-next-line promise/catch-or-return
    this.$store.dispatch("application/getStatus").finally(() => {
      statusToRoute.getName(this.$store, (name) => {
        next(to.name === name ? null : { name });
      });
    });
  },
  data: () => ({
    request: false,
  }),
  computed: {
    step() {
      const { status = "", type = "" } = this.application;

      return statusToRoute[type + "_" + status].step;
    },
    theme() {
      return window.envWebpack.theme;
    },
    showBack() {
      return this.$route.meta.back === undefined ? true : this.$route.meta.back;
    },
    showInfo() {
      return !isPartnerApp();
    },
  },
  watch: {
    "this.$store.state.application.init": {
      handler() {
        if (this.$store.state.application.init) {
          this.checkTermAndAmount();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("esia/getData");
  },
  methods: {
    onBack() {
      if (this.request) {
        return;
      }
      this.request = true;
      const params = {
        loan_application_id: this.application.id,
        front_loan_application_status: this.application.status,
      };

      this.$infra.jsonrpc
        .back(params)
        .then(async ({ status }) => {
          if (status === "success") {
            await this.$store.dispatch("application/getStatusAndCheckRoute");
            this.request = false;
            return;
          }
          this.request = false;
          throw new Error();
        })
        .catch((err) => {
          reloadPage(err);

          console.error(err);
          throw err;
        });
    },
    checkTermAndAmount() {
      const { term, amount } = this.$route.query;

      if (!(term && amount)) {
        return;
      }

      this.$store.dispatch("application/updateTermAndAmount", {
        term,
        amount,
      });
    },
  },
};
</script>

<style lang="css" src="./form.css"></style>
